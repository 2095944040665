@import url('https://fonts.cdnfonts.com/css/poppins');

.solutions {
    display: flex;
    max-width: 1680px;
    height: 694px;
    padding: 160px 0;
    flex-direction: column;
    align-items: flex-start;
    gap: 140px;

    &__item {
        display: flex;
        flex-direction: row;
        font-family: 'Poppins', sans-serif;
        gap: 97px;
        max-width: 1320px;
        margin-left: 180px;
        margin-right: 180px;

        &_title {
            font-family: 'Poppins', sans-serif;
            font-size: 32px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.64px;
            text-transform: uppercase;
            color: #0083FF;
            margin-bottom: 40px;
        }

        &_subtitle {
            font-family: 'Poppins', sans-serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: uppercase;
            color: #333;
            margin-bottom: 20px;
        }

        &_txt {
            font-family: 'Poppins', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            width: 611px;
        }

        &_img {
            width: 612px;
            height: 277px;
        }
    }
}

@media (max-width:1679px) and (min-width: 1249px) {
    .solutions {
        width: 100%;
        padding: 157px 0px;
        gap: 140px;
        height: 740px;

        &__item {
            margin: 0 auto;
            height: 312px;
            gap: 25px;
            justify-content: space-between;
            min-width: 1190px;

            &_title {
                width: 477px;
            }
            &_txt {
                width: 477px;
            }
        }
    }
}

@media (max-width:1249px) and (min-width: 767px) {
    .solutions {
        width: 100%;
        padding: 100px 0px;
        height: 1410px;

        &__item {
            margin: 0 auto;
            display: flex;
            flex-direction: column;

            &_title {
                width: 609px;
            }
            &_subtitle {
                width: 609px;
            }
            &_txt {
                width: 609px;
            }
        }
    }

}

@media (max-width:767px) and (min-width: 429px) {
    .solutions {
        width: 100%;
        padding: 80px 0px;
        height: 1191px;
        // height: 1610px;
        gap: 80px;

        &__item {
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            height: auto;
            // max-height: 560px;
            gap: 60px;

            &_title {
                width: 100%;
                width: 350px;
                margin-bottom: 24px;
            }
            &_subtitle {
                width: 350px;
            }
            &_txt {
                width: 350px;
                font-size: 14px;
            }
            &_img {
                width: 350px;
                height: 159px;
            }
        }
    }

}

@media (max-width:429px) and (min-width: 310px) {
    .solutions {
        width: 100%;
        padding: 80px 0px;
        height: 1166px;
        gap: 80px;

        &__item {
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            height: auto;
            // max-height: 560px;
            gap: 40px;

            &_title {
                // width: 100%;
                width: 272px;
                margin-bottom: 24px;
                font-size: 28px;
            }
            &_subtitle {
                width: 272px;
                font-size: 20px;
            }
            &_txt {
                width: 272px;
                font-size: 14px;
            }
            &_img {
                width: 272px;
                height: 123px;
            }
        }
    }

}