@import url('https://fonts.cdnfonts.com/css/poppins');

.footer {
    width: 1680px;
    // height: 895px;
    height: auto;
    background-image: url("../images/footer.png");
    background-size: cover;
    display: flex;
    flex-direction: column;
    &__info_wrapper {
        display: flex;
        flex-direction: row;
        margin: 100px  auto 115px;
    }
    &__info {
        display: flex;
        flex-direction: column;
        background: rgba(0, 0, 0, 0.20);
        backdrop-filter: blur(50px);
        margin-right: 100px;
        height: 593px;
        border-radius: 16px;
        &_title {
            font-family: 'Poppins', sans-serif;
            color: #FFF;
            text-align: center;
            font-size: 32px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-top: 60px;
            margin-bottom: 10px;
        }
        &_subtitle {
            color: rgba(255, 255, 255, 0.70);
            text-align: center;
            font-family: 'Poppins', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 86px;
            width: 610px;

        }
        &_row {
            display: flex;
            flex-direction: row;
            // justify-content: center;
            text-decoration: none;
            margin-left: 80px;
            gap: 8px;
            align-items: center;
        }

        &_img {
            width: 80px;
            height: 80px;
        }
        &_link_text {
            color: #FFF;
            font-family: 'Poppins', sans-serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            max-width: 333px;
        }
    }



    &__img_wrapper {
        display: flex;
        flex-direction: column;
        
    }

    &__img_top {
        display: flex;
        flex-direction: row;
        gap: 40px;
    }

    &__img_logo {
        width: 214px;
        height: 229px;
    }

    &__img_qr {
        width: 214px;
        height: 229px;
        border-radius: 16px;
    }
    &__img_googlePlay {
        // width: 341px;
        // height: 160px;
        width: 436px;
        height: 200px;
        position: relative;
        right: 56px;
    }


    &__nav {
        display: flex;
        justify-content: center;
        padding-top: 24px;
        align-items: center;
        padding-bottom: 10px;

        &_links_wrapper {
            display: flex;
            gap: 27.5px;
            margin-left: 366px;
            height: 41.6px;
            align-items: center;
        }

        &_link {
            font-family: 'Poppins', sans-serif;
            text-align: center;
            font-size: 13.714px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.274px;
            color: #fff;
            text-decoration: none;
            cursor: pointer;
            
            &_login {
                font-family: 'Poppins', sans-serif;
                display: block;
                padding: 9px 32.5px;
                justify-content: center;
                align-items: center;
                text-decoration: none;
                cursor: pointer;
                color: #0083FF;
                border-radius: 8px;
                background-color: #fff;
                margin-left: 15.5px;

                &:hover {
                    background: #0065C5;
                    color: #fff;
                }

                &:active {
                    background: #0065C5;
                    color: #fff;
                    box-shadow: 3px 4px 20px 0px rgba(0, 0, 0, 0.25) inset;
                }
            }
        }


    }

    &__logo_txt {
        text-align: center;
        font-family: Poppins;
        font-size: 32px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        color: #fff;
        z-index: 100;
        // margin-left: 180px;
    }
}

@media (max-width:1679px) and (min-width: 1299px) {
    .footer {
        max-width: 100%;
        height: auto;

        // background-size: 100% 100%;
        background-size: cover;
        background-position: center;
        &__info {
            width: 540px;
            height: 593px;
            &_wrapper {
                // margin-left: 160px;
                // margin-right: 160px;
            }
            &_subtitle {
                margin-bottom: 76px;
                text-align: center;
                width: 100%;
            }
            &_row {
                margin-left: 62px;
            }
        }
        &__logo_txt {
            margin-left: 0;
        }
        &__nav {
            &_links_wrapper {
                margin-left: 190px;
                height: 41.6px;
            }
        }

    }

}

@media (max-width:1299px) and (min-width: 767px) {
    .footer {
        max-width: 100%;
        // height: 800px;
        height: auto;
        background-size: cover;
        background-position: center;
        &__logo_svg {
            width: 115.738px;
            height: 72px;
        }
        &__img_wrapper {
            display: none;
        }
        &__info {
            width: 607px;
            height: 457px;
            margin: 0 auto 130px;
            border-radius: 16px;
            padding-bottom: 50px;
            
            &_wrapper {
                margin: 156px auto 0;
                width: 607px;
            }
            &_subtitle {
                margin-bottom: 76px;
                text-align: center;
                width: 100%;
            }
            &_row {
                margin-left: 62px;
            }
        }
        &__logo_txt {
            margin-left: 0;
            font-size: 66px;
        }
        &__nav {
            // display: none;
            padding-bottom: 0px;
            display: flex;
            flex-direction: column;
            gap: 70px;
            &_links_wrapper {
                margin-left: 0;
                justify-content: center;
                height: 41.6px;
                margin-bottom: 30px;
            }
        }
    }

}

@media (max-width:767px) and (min-width: 429px) {
    .footer {
        max-width: 100%;
        // height: 800px;
        height: auto;
        background-size: cover;
        background-position: center;

        &__logo_svg {
            width: 115.738px;
            height: 72px;
        }
        &__img_wrapper {
            display: none;
        }
        &__info {
            width: 350px;
            height: 378px;
            margin: 0 auto 60px;
            border-radius: 16px;
            
            &_wrapper {
                margin: 120px auto 0;
                width: 350px;
                height: 378px;
            }
            &_subtitle {
                // width: 540px;
                margin-bottom: 76px;
                font-size: 14px;
                width: 214px;
                // justify-content: center;
                margin: 0 auto 30px;
                display: flex;
            }
            &_title {
                font-size: 24px;
                // width: 540px;
                margin-top: 40px;
            }
            &_row {
                margin-left: 27.5px;
                // margin: 0 auto;
            }
            &_img {
                width: 60px;
                height: 60px;
            }
            &_link_text {
                font-size: 14px;
                max-width: 220px
            }
        }
        &__logo_txt {
            margin-left: 0;
            font-size: 66px;
        }
        &__nav {
            // display: none;
            display: flex;
            flex-direction: column;
            gap: 70px;
            margin-top: 50px;
            padding-bottom: 0px;
            &_links_wrapper {
                height: auto !important; 
                flex-direction: column;
                margin-left: 0;
                justify-content: center;
                height: 32px;
                margin-bottom: 100px;
            }
        }
    }

}

@media (max-width:429px) and (min-width: 310px) {
    .footer {
        max-width: 100%;
        // height: 800px;
        height: auto;
        background-size: cover;
        background-position: center;

        &__logo_svg {
            width: 115.738px;
            height: 72px;
        }
        &__img_wrapper {
            display: none;
        }
        &__info {
            width: 300px;
            height: 378px;
            margin: 0 auto 60px;
            border-radius: 16px;
            
            &_wrapper {
                margin: 120px auto 0;
                width: 272px;
                height: 378px;
                width: 300px;
            }
            &_subtitle {
                // width: 540px;
                margin-bottom: 76px;
                font-size: 14px;
                width: 214px;
                // justify-content: center;
                margin: 0 auto 30px;
                display: flex;
            }
            &_title {
                font-size: 24px;
                // width: 540px;
                margin-top: 40px;
            }
            &_row {
                margin-left: 27.5px;
                // margin: 0 auto;
            }
            &_img {
                width: 60px;
                height: 60px;
            }
            &_link_text {
                font-size: 14px;
                max-width: 220px;
            }
        }
        &__logo_txt {
            margin-left: 0;
            font-size: 66px;
        }
        &__nav {
            // display: none;
            margin-top: 50px;
            padding-bottom: 0px;
            display: flex;
            flex-direction: column;
            gap: 60px;
            &_links_wrapper {
                height: auto;
                flex-direction: column;
                margin-left: 0;
                justify-content: center;
                // height: 32px;
                height: auto;
                margin-bottom: 120px;
            }
        }
    }

}