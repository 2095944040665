@import url('https://fonts.cdnfonts.com/css/poppins');

.aboutCompany {
    padding-top: 160px;
    width: 1680px;
    height: 683px;
    display: flex;
    // padding: 0px 180px 157px 180px;
    
    flex-direction: row;
    align-items: flex-start;

    &__info {
        display: flex;
        flex-direction: column;
        margin-right: 97px;
        margin-left: 180px;

        &_title {
            font-family: 'Poppins', sans-serif;
            color: #0083FF;
            font-size: 32px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.64px;
            text-transform: uppercase;
            margin-top: 59px;
            margin-bottom: 40px;
        }

        &_txt {
            color: #333;
            font-family: 'Poppins', sans-serif;
            margin-bottom: 59px;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 160%;
        }
    }

    &__img {
        width: 612px;
        height: 526px;
        object-fit: cover;
        margin-right: 180px;
    }
}

@media (max-width:1679px) and (min-width: 1299px) {
    .aboutCompany {
        width: 100%;
        padding-top: 160px;
        height: 664px;
        justify-content: center;
        &__info {
            margin-left: 0px;
            width: 458px;
            height:  504px;

            &_txt {
                margin-bottom: 0;
            }
        }
        &__info_title {
            margin-top: 0;
        }
        &__img {
            width: 565px;
            height: 484px;
            margin-right: 0;
            margin-top: 10px;
        }
    }
}

@media (max-width:1299px) and (min-width: 768px) {
    .aboutCompany {
        width: 100%;
        padding-top: 103px;
        height: 1049px;
        // height: auto;
        flex-direction: column;
        margin: 0 auto;
        width: 608px;
        // height: 664px;
        &__info {
            margin-left: 0px;
            margin-right: 0px;
            width: 608px;
            margin-bottom: 60px;

            &_txt {
                margin-bottom: 0;
            }
        }
        &__info_title {
            margin-top: 0;
        }
        &__img {
            width: 611px;
            height: 481px;
            margin-right: 0;
        }
    }

}

@media (max-width:787px) and (min-width: 429px) {
    .aboutCompany {
        width: 100%;
        padding-top: 103px;
        height: 1049px;
        flex-direction: column;
        margin: 0 auto;
        width: 350px;
        // height: 664px;
        &__info {
            margin-left: 0px;
            margin-right: 0px;
            width: 350px;
            margin-bottom: 60px;

            &_txt {
                margin-bottom: 0;
            }
        }
        &__info_title {
            margin-top: 0;
        }
        &__img {
            width: 350px;
            height: 277px;
            margin-right: 0;
        }
    }

}

@media (max-width:429px) and (min-width: 310px) {
    .aboutCompany {
        width: 100%;
        padding-top: 103px;
        height: 1089px;
        flex-direction: column;
        margin: 0 auto;
        width: 272px;
        // height: 664px;
        &__info {
            margin-left: 0px;
            margin-right: 0px;
            width: 272px;
            margin-bottom: 48px;

            &_txt {
                margin-bottom: 0;
            }
        }
        &__info_title {
            margin-top: 0;
            font-size: 24px;
        }
        &__img {
            width: 272px;
            height: 217px;
            margin-right: 0;
        }
    }

}