@import url('https://fonts.cdnfonts.com/css/poppins');

.services {
    display: flex;
    width: 1680px;
    // padding: 160px 180px;
    // flex-direction: column;
    // align-items: flex-start;
    // gap: 140px;

    &__left {
        width: 750px;
        margin-right: 117px;
        margin-left: 180px;
        margin-top: 160px;
        margin-bottom: 160px;
    }

    &__right {

        margin-right: 180px;
        margin-top: 160px;
        margin-bottom: 160px;
    }


    &__title {
        color: #0083FF;
        font-family: 'Poppins', sans-serif;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.64px;
        text-transform: uppercase;
        margin-bottom: 40px;
    }

    &__subtitle {
        color: #333;
        font-family: 'Poppins', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
        margin-bottom: 20px;
    }

    &__txt {
        color: #333;
        font-family: 'Poppins', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; 
        margin-bottom: 40px;
        width: 736px;
    }

    &__link {
        border-radius: 8px;
        border: 2px solid #0083FF;
        text-align: center;
        font-family: 'Poppins', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        color: #0083FF;
        justify-content: center;
        align-items: center;
        display: flex;
        width: 232px;
        height:78px;
        text-decoration: none;
        box-sizing: border-box;
        
        &:hover {
            background: #0065C5;
            color: #FFF;
            border: 2px solid #0065C5;
        }

        &:active {
            background: #0083FF;
            color: #FFF;
            border: 2px solid #0083FF;
            box-shadow: 3px 4px 20px 0px rgba(0, 0, 0, 0.25) inset;
        }
    }

    &__mainImg {
        width: 453px;
        height: 412px;
        // object-fit: contain;
        margin-bottom: 53px;
    }

    &__img {
        height: 109px;
        width: 145px;
        object-fit: contain;
    }
    &__img_Wrapper {
        display: flex;
        gap: 9px;
    }
}

@media (max-width:1679px) and (min-width: 1250px) {
    .services {
        width: 100%;
        height: 960px;
        display: flex;
        justify-content: center;
        &__left {
            margin-right: 112px;
            margin-left: 0;
            // margin-left: 160px;
            margin-top: 160px;
            margin-bottom: 160px;
            width: 604px;
            height: 640px;
        }
        &__txt {
            width: 604px;
        }
        &__right {
            margin-right: 0;
        }

    }
}

@media (max-width:1250px) and (min-width: 767px) {
    .services {
        width: 100%;
        height: 1306px;
        flex-direction: column;
        justify-content: unset;    
        // margin: 0 auto;
        align-items: center;
        &__img_Wrapper {
            display: flex;
        }
        &__secondary_img {
            display: flex;
            flex-direction: column;
            height: 446px;
            width: 107px;
            justify-content: space-between;
        }
        &__img {
            width: 107px;
        }
        &__mainImg {
            width: 490px;
            height: 446px;
            // object-fit: cover;
            margin-right: 10px;
            margin-bottom: 0px;
        }
        &__left {
            margin-right: 0;
            margin-left: 0;
            margin-top: 100px;
            margin-bottom: 0px;
            width: 604px;
            height: fit-content;

        }
        &__txt {
            width: 604px;
        }
        &__right {
            margin-right: 0;
            margin-top: 20px;
            margin-bottom: 0;
        }

    }

}

@media (max-width:767px) and (min-width: 429px) {
    .services {
        width: 100%;
        height: 1012px;
        flex-direction: column;
        justify-content: unset;    
        // margin: 0 auto;
        // align-items: center;
        align-items: center;
        &__title {
            width: 350px;
            margin-bottom: 32px;
        }
        &__img_Wrapper {
            display: flex;
            gap: 32px;
            width: 350px;
        }
        &__secondary_img {
            display: flex;
            flex-direction: column;
            height: 236px;
            width: 59px;
            justify-content: space-between;
        }
        &__img {
            height: 76px;
            width: 59px;
        }
        &__mainImg {
            width: 259px;
            height: 236px;
            // object-fit: cover;
            margin-right: 0;
            margin-bottom: 0px;
        }
        &__left {
            margin-right: 0;
            margin-left: 0;
            margin-top: 80px;
            margin-bottom: 0px;
            width: 350px;
            height: fit-content;

        }
        &__txt {
            width: 604px;
            font-size: 14px;
            width: 350px;
        }
        &__right {
            margin-right: 0;
            margin-top: 20px;
            margin-bottom: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        &__link {
            width: 350px;
        }

    }

}

@media (max-width:429px) and (min-width: 310px) {
    .services {
        width: 100%;
        height: 1042px;
        flex-direction: column;
        justify-content: unset;    
        // margin: 0 auto;
        // align-items: center;
        align-items: center;
        &__title {
            width: 271px;
            margin-bottom: 16px;
            font-size: 28px;
        }
        &__img_Wrapper {
            display: flex;
            gap: 26px;
            width: 271px;
        }
        &__secondary_img {
            display: flex;
            flex-direction: column;
            height: 184px;
            width: 44px;
            justify-content: space-between;
        }
        &__img {
            height: 60px;
            width: 44px;
        }
        &__mainImg {
            width: 201px;
            height: 184px;
            // object-fit: cover;
            margin-right: 0;
            margin-bottom: 0px;
        }
        &__left {
            margin-right: 0;
            margin-left: 0;
            margin-top: 80px;
            margin-bottom: 0px;
            width: 271px;
            height: fit-content;

        }
        &__txt {
            font-size: 14px;
            width: 272px;
            margin-bottom: 32px;
        }
        &__right {
            margin-right: 0;
            margin-top: 20px;
            margin-bottom: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        &__link {
            width: 271px;
        }

    }

}