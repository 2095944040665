@import url('https://fonts.cdnfonts.com/css/poppins');

.benefits {
    width: 1680px;
    height: 972px;
    background-color: #E7ECF2;

    &__text {
        padding-top: 120px;
        font-family: 'Poppins', sans-serif;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.64px;
        text-transform: uppercase;
        color: #333;
        text-align: center;
        margin-bottom: 60px;
    }

    &__wrapper {
        margin-left: 180.5px;
        margin-right: 180.5px;
        display: flex;
        flex-wrap: wrap;
        gap: 23px;
        width: 1319px;
    }

    &__cell {
        display: flex;
        flex-direction: column;
        width: 424px;
        height: 300px;
        background-color: #fff;
        align-items: center;
        border-radius: 8px;
        
        &_img {
            width: 80px;
            height: 80px;
            margin-top: 40px;
            margin-bottom: 20px;
        }
        &_title {
            font-family: 'Poppins', sans-serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: uppercase;
            color: #333;
            margin-bottom: 8px;
        }
        &_txt {
            margin-left: 57.5px;
            margin-right: 57.5px;
            font-family: 'Poppins', sans-serif;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            color: #676767;
        }
    }
}

@media (max-width:1679px) and (min-width: 1199px) {
    .benefits {
        width: 100%;
        height: 972px;
        &__text {
            padding-top: 118px;
            
        }
        &__wrapper {
            // margin-left: 160px;
            // margin-right: 160px;
            margin: 0 auto;
            width: auto;
            justify-content: center;
            max-width: 1120px;
        }

        &__cell {
            width: 355px;
            height: 300px;
        }
    }
}

@media (max-width:1199px) and (min-width: 767px) {
    .benefits {
        width: 100%;
        height: 1255px;
        &__text {
            padding-top: 100px;
        }
        &__wrapper {
            margin: 0 auto;
            width: 608px;
            justify-content: center;
        }

        &__cell {
            width: 291px;
            height: 300px;
            &_txt {
                margin-left: 34.5px;
                margin-right: 34.5px;
            }
        }
    }

}

@media (max-width:767px) and (min-width: 429px) {
    .benefits {
        width: 100%;
        height: 2168px;
        &__text {
            padding-top: 100px;
        }
        &__wrapper {
            margin: 0 auto;
            width: 347px;
            justify-content: center;
            gap: 16px;
        }

        &__cell {
            width: 347px;
            height: 300px;
            &_title {
                font-size: 16px;
            }   
            &_txt {
                margin-left: 34.5px;
                margin-right: 34.5px;
                font-size: 14px;
            }
        }
    }

}


@media (max-width:429px) and (min-width: 310px) {
    .benefits {
        width: 100%;
        // height: 2027px;
        height: 2050px;
        &__text {
            padding-top: 100px;
        }
        &__wrapper {
            margin: 0 auto;
            width: 272px;
            justify-content: center;
            gap: 16px;
        }

        &__cell {
            width: 272px;
            height: 280px;
            &_title {
                font-size: 16px;
            }   
            &_txt {
                margin-left: 34.5px;
                margin-right: 34.5px;
                font-size: 14px;
            }
        }
    }

}