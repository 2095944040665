@import url('https://fonts.cdnfonts.com/css/poppins');

*, html {

    scroll-behavior: smooth !important;
}

.header {
    max-width: 1680px;
    max-height: 767px;
    width: 1680px;
    height: 767px;
    background-size: 1680px 767px;
    background-image: url("../images/headerMain.png");
    // background-size: cover;  

    &__nav {
        display: flex;
        justify-content: center;
        padding-top: 24px;
        &_burger {
            display: none;
        }
        &_links_wrapper {
            display: flex;
            gap: 27.5px;
            margin-left: 366px;
            height: 41.6px;
            align-items: center;
        }

        &_link {
            font-family: 'Poppins', sans-serif;
            text-align: center;
            font-size: 13.714px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.274px;
            color: #fff;
            text-decoration: none;
            cursor: pointer;
            
            &_login {
                font-family: 'Poppins', sans-serif;
                display: block;
                padding: 9px 32.5px;
                justify-content: center;
                align-items: center;
                text-decoration: none;
                cursor: pointer;
                color: #0083FF;
                border-radius: 8px;
                background-color: #fff;
                margin-left: 15.5px;
                box-sizing: border-box;

                &:hover {
                    background: #0065C5;
                    color: #fff;
                }

                &:active {
                    background: #0065C5;
                    color: #fff;
                    box-shadow: 3px 4px 20px 0px rgba(0, 0, 0, 0.25) inset;
                }
            }
        }


    }


    &__info {
        display: inline-flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 60px;
        margin-top: 137px;
        margin-left: 130px;

        &_main {
            gap: 20px;
            display: flex;
            flex-direction: column;
        }
        &_motto {
            font-family: 'Poppins', sans-serif;
            font-size: 60px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: uppercase;
            color: #fff;
        }


        &_txt {
            font-family: 'Poppins', sans-serif;
            font-size: 28px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            color: #fff;
        }

        &_txt--bold {
            font-weight: 600;
        }

        &_btn {
            display: flex;
            width: 350px;
            height: 72px;
            justify-content: center;
            align-items: center;
            border-radius: 16px;
            background-color: #0083FF;
            color: #fff;
            z-index: 100;
            font-family: 'Poppins', sans-serif;
            text-decoration: none;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            box-sizing: border-box;


            &:hover {
                background: #0065C5;
                color: #fff;
            }

            &:active {
                background: #0065C5;
                color: #fff;
                box-shadow: 3px 4px 20px 0px rgba(0, 0, 0, 0.25) inset;
            }
        }
    }



    &__logo_txt {
        text-align: center;
        font-family: 'Poppins', sans-serif;
        font-size: 32px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        color: #fff;
        z-index: 100;
    }


}

@media (max-width:1679px) and (min-width: 1199px) {
    .header {
        // width: 100% !important;
        max-width: 100%;
        background-image: none;
        background: url("../images/headerMain.png") 420px 0 no-repeat ;
        
        // min-width: 100%;
        height: 657.43px;
        background-size: 1680px 657.43px;
        background-position: 0 0;

        &__nav {
            &_links_wrapper {
                margin-left: 190px;
                height: 41.6px;
            }
        }

        &__info {
            margin-left: 111px;
            gap: 51px;
            &_motto {
                font-family: 'Poppins', sans-serif;
                font-size: 51.429px;
            }
            &_main {
                gap: 17px;
                display: flex;
                flex-direction: column;
            }
    
    
            &_txt {
                font-size: 24px;
            }

        }
    }

}

@media (max-width:1199px) and (min-width: 767px) {
    .header {
        max-width: 100%;
        height: 959.814px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 63% 50%;

        display: flex;
        flex-direction: column;
        align-items: center;

        &__logo_txt {
            margin-left: 80px;
        }
        &__nav {
            display: flex;
            justify-content: space-between;
            width: 100%;
            align-items: center;

            &_link {
                width: 0;
                height: 0;
                display: none;
            }
 

            &_xMark {
                position: absolute;
                right: 238px;
                background: #fff;
                padding: 11px;
                top: 0;
                width: 26px;
                height: 26px;
                cursor: pointer;
            }
            
            &_link_login {
                text-align: center;
                justify-content: center;
                display: flex;
                margin-left: 0;
                padding: 0;
                background-color: #0083FF;
                color: #fff;
                display: none;
                cursor: pointer;
                box-sizing: border-box;
                &_open {
                    display: block;
                    width: 207px;
                    height: 41px;
                    padding: 0;
                    background-color: #0083FF;
                    color: #fff;
                    text-align: center;
                    justify-content: center;
                    display: flex;
                    margin-left: 0;
                    font-family: 'Poppins', sans-serif;
                    text-decoration: none;
                    align-items: center;
                    border-radius: 8px;
                    cursor: pointer;
                    &:hover {
                        background: #0065C5;
                        color: #fff;
                    }
        
                    &:active {
                        background: #0065C5;
                        color: #fff;
                        box-shadow: 3px 4px 20px 0px rgba(0, 0, 0, 0.25) inset;
                    }
                }
            }
            
            &_burger {
                display: block;
                margin-right: 33.5px;
                width: 32px;
                height: 20px;
                cursor: pointer;
            }
            &_link_open {
                font-family: 'Poppins', sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                color: #333;
                text-decoration: none;
                margin-left: 16px;
                margin-bottom: 40px;
                display: block;
                cursor: pointer;
            }
            &_links_wrapper {
                margin-left: 190px;
                height: 41.6px;

                position: absolute;
                width: 0;
                height: 0;
                right: -238px;
                -webkit-animation: fadeOut 500ms;
                -moz-animation: fadeOut 500ms;
                animation: fadeOut 500ms; 

                &_open {
                    display: flex;
                    flex-direction: column;
                    z-index: 999;
                    position: absolute;
                    width: 223px;
                    height: 573px;
                    top: 0;
                    right: 0;
                    background-color: #fff;
                    padding: 32px 0px 162px 16px;
                    transition: right 0.5s ease-in-out;  
                }

                &_closed_true {
                    transition: right 0.5s ease-in-out;  
                    right: 0;
                }
            }
        }

        &__info {
            margin-left: 0px;
            gap: 60px;
            align-items: center;
            &_motto {
                font-family: 'Poppins', sans-serif;
                font-size: 60px;
                text-align: center;
            }
            &_main {
                gap: 24px;
                display: flex;
                flex-direction: column;
                
            }
    
    
            &_txt {
                font-size: 28px;
                text-align: center;
            }
        }
    }

}


@media (max-width:767px) and (min-width: 429px) {
    .header {
        max-width: 100%;
        height: 720px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 59% 50%;

        display: flex;
        flex-direction: column;
        align-items: center;

        &__logo_txt {
            margin-left: 24px;
        }
        &__nav {
            display: flex;
            justify-content: space-between;
            width: 100%;
            align-items: center;

            &_link {
                width: 0;
                height: 0;
                display: none;
            }
 

            &_xMark {
                position: absolute;
                right: 238px;
                background: #fff;
                padding: 11px;
                top: 0;
                width: 26px;
                height: 26px;
                cursor: pointer;
            }
            
            &_link_login {
                text-align: center;
                justify-content: center;
                display: flex;
                margin-left: 0;
                padding: 0;
                background-color: #0083FF;
                color: #fff;
                display: none;
                cursor: pointer;
                &_open {
                    display: block;
                    width: 207px;
                    height: 41px;
                    padding: 0;
                    background-color: #0083FF;
                    color: #fff;
                    text-align: center;
                    justify-content: center;
                    display: flex;
                    margin-left: 0;
                    font-family: 'Poppins', sans-serif;
                    text-decoration: none;
                    align-items: center;
                    border-radius: 8px;
                    cursor: pointer;
                    &:hover {
                        background: #0065C5;
                        color: #fff;
                    }
        
                    &:active {
                        background: #0065C5;
                        color: #fff;
                        box-shadow: 3px 4px 20px 0px rgba(0, 0, 0, 0.25) inset;
                    }
                }
            }
            
            &_burger {
                display: block;
                margin-right: 24px;
                width: 32px;
                height: 20px;
                cursor: pointer;
            }
            &_link_open {
                font-family: 'Poppins', sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                color: #333;
                text-decoration: none;
                margin-left: 16px;
                margin-bottom: 40px;
                display: block;
                cursor: pointer;
            }
            &_links_wrapper {
                margin-left: 190px;
                height: 41.6px;
                position: absolute;
                width: 0;
                height: 0;
                right: -238px; 
                &_open {
                    display: flex;
                    flex-direction: column;
                    z-index: 999;
                    position: absolute;
                    width: 223px;
                    height: 573px;
                    top: 0;
                    right: 0;
                    // height: 100%;
                    background-color: #fff;
                    padding: 32px 0px 116px 16px;
                    // -webkit-transition: all 300ms ease-in-out;
                    // transition: all 300ms ease-in-out;  
                    // transition:right 1s linear;
                    transition: right 0.5s ease-in-out;  
                    // transition: display 1s linear;  
                    // transform:translate(0);
                    // opacity: 1;
                }

                &_closed_true {
                    transition: right 0.5s ease-in-out;  
                    right: 0;
                }
            }
        }

        &__info {
            margin-left: 0px;
            gap: 60px;
            align-items: center;
            margin-top: 106px;
            &_motto {
                font-family: 'Poppins', sans-serif;
                font-size: 40px;
                text-align: center;
            }
            &_main {
                gap: 24px;
                display: flex;
                flex-direction: column;
                
            }
    
    
            &_txt {
                font-size: 20px;
                text-align: center;
            }
        }
    }

}

@media (max-width:429px) and (min-width: 310px) {
    .header {
        max-width: 100%;
        height: 619px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 57% 50%;

        display: flex;
        flex-direction: column;
        align-items: center;

        &__logo_txt {
            margin-left: 24px;
        }
        &__nav {
            display: flex;
            justify-content: space-between;
            width: 100%;
            align-items: center;

            &_link {
                width: 0;
                height: 0;
                display: none;
            }
 

            &_xMark {
                position: absolute;
                right: 238px;
                background: #fff;
                padding: 11px;
                top: 0;
                width: 26px;
                height: 26px;
                cursor: pointer;
            }
            
            &_link_login {
                text-align: center;
                justify-content: center;
                display: flex;
                margin-left: 0;
                padding: 0;
                background-color: #0083FF;
                color: #fff;
                display: none;
                cursor: pointer;
                &_open {
                    display: block;
                    width: 207px;
                    height: 41px;
                    padding: 0;
                    background-color: #0083FF;
                    color: #fff;
                    text-align: center;
                    justify-content: center;
                    display: flex;
                    margin-left: 0;
                    font-family: 'Poppins', sans-serif;
                    text-decoration: none;
                    align-items: center;
                    border-radius: 8px;
                    cursor: pointer;
                    &:hover {
                        background: #0065C5;
                        color: #fff;
                    }
        
                    &:active {
                        background: #0065C5;
                        color: #fff;
                        box-shadow: 3px 4px 20px 0px rgba(0, 0, 0, 0.25) inset;
                    }
                }
            }
            
            &_burger {
                display: block;
                margin-right: 33px;
                width: 32px;
                height: 20px;
                cursor: pointer;
            }
            &_link_open {
                font-family: 'Poppins', sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                color: #333;
                text-decoration: none;
                margin-left: 16px;
                margin-bottom: 40px;
                display: block;
                cursor: pointer;
            }
            &_links_wrapper {
                margin-left: 190px;
                height: 41.6px;
                position: absolute;
                width: 0;
                height: 0;
                right: -238px; 
                &_open {
                    display: flex;
                    flex-direction: column;
                    z-index: 999;
                    position: absolute;
                    width: 223px;
                    height: 573px;
                    top: 0;
                    right: 0;
                    // height: 100%;
                    background-color: #fff;
                    padding: 32px 0px 14px 16px;
                    // -webkit-transition: all 300ms ease-in-out;
                    // transition: all 300ms ease-in-out;  
                    // transition:right 1s linear;
                    transition: right 0.5s ease-in-out;  
                    // transition: display 1s linear;  
                    // transform:translate(0);
                    // opacity: 1;
                }

                &_closed_true {
                    transition: right 0.5s ease-in-out;  
                    right: 0;
                }
            }
        }

        &__info {
            margin-left: 0px;
            gap: 40px;
            align-items: center;
            margin-top: 86px;
            &_motto {
                font-family: 'Poppins', sans-serif;
                font-size: 32px;
                text-align: center;
            }
            &_main {
                gap: 17px;
                display: flex;
                flex-direction: column;
                
            }
    
    
            &_txt {
                font-size: 18px;
                text-align: center;
            }

            &_btn {
                width: 272px;
            }
        }
    }

}
