@import url('https://fonts.cdnfonts.com/css/poppins');

.customer {
    height: 548px;
    width: 1680px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    

    &__wrapper_top {
        margin-top: 160px;
        margin-left: 180px;
        margin-right: 180px;
    }
    &__title {
        font-family: 'Poppins', sans-serif;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.64px;
        text-transform: uppercase;
        color: #0083FF;
        margin-bottom: 20px;
    }

    &__sub_title {
        font-family: 'Poppins', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        color: #333;
        margin-bottom: 40px;
    }

    &__wrapper_bot {
        display: flex;
        margin-left: 180px;
        margin-right: 180px;
        gap: 20px;
        flex-direction: row;
        // margin-bottom: 160px;
    }

    &__card {
        display: flex;
        padding: 24px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 16px;
        border-radius: 8px;
        background: rgba(231, 236, 242, 0.50);
        width: 379px;
        height: 172px;

        &_txt {
            font-family: 'Poppins', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            color: #797979;
            align-self: stretch;
        }

        &_img {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            object-fit: cover;
        }

        &_info {
            display: flex;
            flex-direction: row;
            gap: 15px;
        }

        &_person {
            font-family: 'Poppins', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            color: #333;
        }

        &_job {
            font-family: 'Poppins', sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 13.6px;
            color: #797979;
        }

        &_person_info {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }
}


@media (max-width:1679px) and (min-width: 1299px) {
    .customer {
        width: 100%;
        height: 587px;
        align-items: center;
        &__sub_title {
            text-align: center;
        }
        &__wrapper_top {
            // margin-left: 160px;
            // margin: 100px auto;
            margin-right: 0px;
            margin-left: 0px;
        }
        &__wrapper_bot {
            margin-left: 0px;
            margin-right: 0px;
        }
        &__card {
            width: 360px;
            height: 208px;
            box-sizing: border-box;
        }
    }
}

@media (max-width:1299px) and (min-width: 767px) {
    .customer {
        width: 100%;
        // height: 947px;
        height: auto;
        &__wrapper_top {
            margin: 100px auto 0 ;
        }
        &__title, &__sub_title  {
            // text-align: center;
            width: 648px;
        }
        &__wrapper_bot {
            margin: 0 auto;
            flex-direction: column;
        }
        &__card {
            width: 600px;
            height: 148px;
        }
    }

}

@media (max-width:767px) and (min-width: 429px) {
    .customer {
        width: 100%;
        height: auto;
        &__wrapper_top {
            margin: 80px auto 0 ;
        }
        &__title, &__sub_title  {
            text-align: center;
            width: 302px;
        }
        &__wrapper_bot {
            margin: 0 auto;
            flex-direction: column;
        }
        &__card {
            width: 302px;
            height: 268px;
        }
    }

}
@media (max-width:429px) and (min-width: 310px) {
    .customer {
        width: 100%;
        height: auto;
        &__wrapper_top {
            margin: 80px auto 0 ;
        }
        &__title, &__sub_title  {
            text-align: center;
            width: 271px;
        }
        &__title {
            font-size: 24px;
        }
        &__sub_title {
            font-size: 14px;
        }
        &__wrapper_bot {
            margin: 0 auto;
            flex-direction: column;
        }
        &__card {
            width: 271px;
            height: 292px;
            box-sizing: border-box;
        }
    }

}