html {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // box-sizing: content-box;
    
}
body {
    width: 100%;
    max-width: 100%;
}
.main {
    // max-width: 1680px !important;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // display: none;

    @media (max-width:1679px) and (min-width: 310px) {
        // width: 100vw;
        // max-width:100% !important;
        // width: 100%;
        // box-sizing: border-box;
    
    }
}