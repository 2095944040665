@import url('https://fonts.cdnfonts.com/css/poppins');

.manuals {
    max-width: 1680px;
    width: 1680px;
    height: 728px;
    // padding: 160px 180px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 60px;
    background: #E7ECF2;

    &__title {
        font-family: 'Poppins', sans-serif;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.64px;
        text-transform: uppercase;
        color: #333;
        margin-top: 160px;
        margin-left: 180px;
    }

    &__wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 24px;
        max-width: fit-content;
        margin-bottom: 160px;
        margin-left: 180px;
        margin-right: 180px;
    }

    &__item {
        width: 647px;
        height: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #fff;
        border-radius: 16px;

        &_title {
            font-family: 'Poppins', sans-serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: uppercase;
            color: #333;
            margin-bottom: 6px;
            
        }

        &_txt {
            font-family: 'Poppins', sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            color: #676767;
        }

        &_link {
            display: block;
            margin-top: 60px;
            margin-bottom: 20px;
            cursor: pointer;
        }

        &_icon {
            display: flex;
            width: 80px;
            height: 80px;
            padding: 10px;
            justify-content: center;
            align-items: center;
            background-image: url("../images/icons/download.svg");
            background-position: center;
            background-size: cover;
            box-sizing: border-box;
            &:hover {
                background-image: url("../images/icons/download_hover.svg");
            }
            &:active {
                background-image: url("../images/icons/download_active.svg");
            }
        }
    }
}


@media (max-width:1679px) and (min-width: 1200px) {
    .manuals {
        width: 100%;
        height: 584px;
        gap: 50px;
        align-items: center;
        &__title {
            margin-top: 130px;
            margin-left: 0px;
        }

        &__wrapper {
            margin-bottom: 96px;
            margin-left: 0px;
            // margin: 0 auto;
            margin-right: 0px;
        }

        &__item {
            width: 542px;
            height: 257px;
            &_icon {
                width: 68.571px;
                height: 68.571px;
                padding: 8.571px;
            }
            &_link {
                margin-top: 51px;
                margin-bottom: 17px;
            }
        }
    }
}

@media (max-width:1200px) and (min-width: 767px) {
    .manuals {
        width: 100%;
        height: 838px;
        gap: 50px;

        &__title {
            // text-align: center;
            // margin-left: 100px;
            margin: 100px auto 0;
        }

        &__wrapper {
            flex-direction: column;
            margin: 0 auto;
            gap: 20px;
        }

        &__item {
            width: 360px;
            height: 260px;
            
            &_icon {
                width: 68.571px;
                height: 68.571px;
                padding: 8.571px;
            }
            &_link {
                margin-top: 51px;
                margin-bottom: 17px;
            }
        }
    }

}

@media (max-width:767px) and (min-width: 429px) {
    .manuals {
        width: 100%;
        height: 803px;
        gap: 60px;

        &__title {
            // text-align: center;
            // margin-left: 100px;
            margin: 100px auto 0;
        }

        &__wrapper {
            flex-direction: column;
            margin: 0 auto;
            gap: 20px;
        }

        &__item {
            width: 360px;
            height: 260px;
            
            &_icon {
                width: 68.571px;
                height: 68.571px;
                padding: 8.571px;
            }
            &_link {
                margin-top: 51px;
                margin-bottom: 17px;
            }
        }
    }

}
@media (max-width:429px) and (min-width: 310px) {
    .manuals {
        width: 100%;
        height: 848px;
        gap: 60px;

        &__title {
            margin: 100px auto 0;
        }

        &__wrapper {
            flex-direction: column;
            margin: 0 auto;
            gap: 60px;
        }

        &__item {
            width: 270px;
            height: 257px;
            &_txt {
                width: 174px;
                text-align: center;
            }
            &_icon {
                width: 68.571px;
                height: 68.571px;
                padding: 8.571px;
            }
            &_link {
                margin-top: 51px;
                margin-bottom: 17px;
            }
        }
    }

}