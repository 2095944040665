@import url('https://fonts.cdnfonts.com/css/poppins');


.mobile {
    width: 1680px;
    height: 1002px;
    background-image: url('../images/mobileBg.png');
    background-size: cover;
    display: flex;
    align-items: flex-end;
    

    &__left {
        margin-left: 180px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        min-width: 638px;   
        margin-bottom: 194px;
        &_img {
            width: 214px;
            height: 229px;
            border-radius: 20px;
        }
        &_title {
            font-family: 'Poppins', sans-serif;
            font-size: 60px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: uppercase;
            color: #fff;
            margin-bottom: 24px;
        }

        &_subtitle {
            font-family: 'Poppins', sans-serif;
            font-size: 32px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            color: #fff;
        }

        &_googlePlay {
            width: 341px;
            height: 160px;
            position: relative;
            right: 43px;
        }

        &_bottom {
            margin-top: 40px;
            display: flex;
            gap: 40px;
            height: 229px;
        }
    }

    &__app {
        width: 522.708px;
        height: 956px;
        object-fit: cover;
        margin-right: 222px;
        margin-left: 122px;
    }

 
}

@media (max-width:1679px) and (min-width: 1250px) {
    .mobile {
        width: 100%;
        height: 916px;
        &__app {
            width: 466.784px;
            height: 840px;
            margin-right: 0;
        }
        &__left {
            min-width: 510px;  
            margin-left: 160px; 
        }
        &__left_title {
            font-size: 48px;
        }
        &__left_bottom {
            width: 372px;
            margin-top: 40px;
            display: flex;
            gap: 20px;
            height: 188px;
        }
        &__left_img {
            width: 176px;
            height: 188px;
        }
    }
}

@media (max-width:1250px) and (min-width: 767px) {
    .mobile {
        width: 100%;
        height: 1111px;
        display: flex;
        flex-direction: column;
        align-items: center;
        &__app {
            width: 415px;
            height: 665px;
            margin-right: 0;
            margin-left: 0;
            object-fit: contain;
            margin-top: 20px;
        }
        &__left {
            margin-top: 119px;
            min-width: 510px;  
            margin-left: 0px; 
            margin-bottom: 0;
            align-items: center;
            &_top {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            &_googlePlay {
                margin: 0 auto;
                position: static;
                width: 380px;
                object-fit: cover;
            }
        }
        &__left_title {
            font-size: 48px;
        }
        &__left_bottom {
            display: none;
        }
    }

}

@media (max-width:767px) and (min-width: 429px) {
    .mobile {
        width: 100%;
        height: 787px;
        display: flex;
        flex-direction: column;
        align-items: center;
        &__app {
            width: 240px;
            height: 395px;
            margin-right: 0;
            margin-left: 0;
            object-fit: contain;
            margin-top: -14px;
        }
        &__left {
            margin-top: 60px;
            min-width: 0;  
            margin-left: 0px; 
            margin-bottom: 0;
            align-items: center;
            &_top {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            &_googlePlay {
                margin: 0 auto;
                position: static;
                width: 340px;
                height: auto;
                object-fit: cover;
            }
        }
        &__left_title {
            font-size: 40px;
            width: 236px;
            text-align: center;
            margin-bottom: 20px;
        }
        &__left_subtitle {
            font-size: 24px;
        }
        &__left_bottom {
            display: none;
        }
    }

}


@media (max-width:429px) and (min-width: 310px) {
    .mobile {
        width: 100%;
        height: 787px;
        display: flex;
        flex-direction: column;
        align-items: center;
        &__app {
            width: auto;
            height: 415px;
            margin-right: 0;
            margin-left: 0;
            object-fit: contain;
            margin-top: -8px;
        }
        &__left {
            margin-top: 60px;
            min-width: 0;  
            margin-left: 0px; 
            margin-bottom: 0;
            align-items: center;
            &_top {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 280px;
            }
            &_googlePlay {
                margin: 0 auto;
                position: static;
                width: 300px;
                height: auto;
                object-fit: cover;
            }
        }
        &__left_title {
            font-size: 40px;
            width: 236px;
            text-align: center;
            margin-bottom: 20px;
        }
        &__left_subtitle {
            font-size: 20px;
        }
        &__left_bottom {
            display: none;
        }
    }

}